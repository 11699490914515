import React from "react"

/** components */
import { ColorPalette } from "../Theme"

/** props */
interface Props {
  backgroundColorOne: string
  backgroundColorTwo: string
  badgeTitle: string
  bottomMargin?: number
  buttonName: string
  buttonUrl?: string
  colorPalette: ColorPalette
  colSizeOne: number
  colSizeTwo: number
  fontTextColor: string
  image: string
  imageTitle: string
  subtitle: string
  title: string
  titleTopLeft: string
  imageSubtitle?: string
  imageTitleSubtitle?: string
  asoEffect: string
}

/** const */
const FlooringProductSection: React.FC<Props> = (props) => {
  return (
    <section
      className={`container-fluid text-left p-5 pt-5 pb-5`}
      style={{
        background: "#EFEFEF",
        color: "#365c70",
      }}
    >
      <div className="container">
        <div
          className="contentHeroLeftTitle hidden-sm hidden-md hidden-lg hidden-xl m-0 p-0"
          style={{ fontSize: "24px", color: "#365c70" }}
        >
          Flooring
        </div>

        <hr
          className="hidden-sm hidden-md hidden-lg hidden-xl style-line"
          style={{
            borderColor: "rgba(0, 0, 0, 0.2)",
            marginTop: "10px",
            marginBottom: "10px",
            color: "",
          }}
        />


       <div class="container m-0 p-0 mt-3 mb-5 d-none d-sm-block">
          <div class="row">
            <div class="col-4 col-md-4">
              <img
                data-aos="Fade"
                src="/flooring/1.jpg"
                loading="lazy"
                decoding="async"
                alt=""
                width="100%"
              />
            </div>

            <div class="col-4 col-md-4">
              <img
                data-aos="Fade"
                src="/flooring/2.jpg"
                loading="lazy"
                decoding="async"
                alt=""
                width="100%"
              />
            </div>

            <div class="col-4 col-md-4">
              <img
                data-aos="Fade"
                src="/flooring/3.jpg"
                loading="lazy"
                decoding="async"
                alt=""
                width="100%"
              />
            </div>
          </div>
        </div>

       <div class="container m-0 p-0 d-block d-sm-none">
          <img
            data-aos="Fade"
            src="/flooring/2.jpg"
            loading="lazy"
            decoding="async"
            alt=""
            width="100%"
          />
        </div>

        <div className="contentHeroSpace hidden-xs"></div>

        <div className="row">
         <div
           className="contentHeroLeftTitle hidden-sm hidden-md hidden-lg hidden-xl m-0 p-0"
           style={{ fontSize: "18px", color: "#365c70" }}
         >
         </div>

         <div
            className="hidden-sm hidden-md hidden-lg hidden-xl mb-5 p-0"
            style={{ fontSize: "18px", color: "" }}
          >
            Norhart Supply has a great selection of flooring for any area in your home or business.  Our products range from carpet to vinyl and tile flooring.  Each type of flooring comes in a variety of colors. When it comes to styles to make your vision become a reality, we have those too!

            <p/>

            <strong>Carpet</strong>
            <br/>
            At Norhart Supply, we have an assortment of carpet products. We have a variety of weights and looms. From commercial to residential we have it all. Ranging from carpet tile to broadloom, Norhart Supply has all the carpet you will need for your building.
            <p/>
            <hr/>

            <strong>Vinyl</strong>
            <br/>
            Norhart Supply carries vinyl flooring in an assortment of colors.  Vinyl flooring provides more durability than hardwood, while still giving the same visual effect.  This flooring is ideal if you have pets or lots of foot traffic and still want the clean look of hardwood, as it holds up better against scratches and dents.  In busy areas, longevity and durability is a key characteristic to look for in flooring.
            <p/>
            <hr/>


            <strong>Tile</strong>
            <br/>
            When it comes to tile flooring, Norhart Supply has a wide variety. We carry tile in numerous colors.  As well as color, we carry a wide range of sizes and options in tile shapes.  We provide the tile needed for rooms varying from large common areas to bathrooms.
            <p/>

          </div>
        </div>
      </div>
    </section>
  )
}

export default FlooringProductSection
